import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Routers from './Router/Routers';

function App() {
  return (
    <div className="App">
    <Navbar/>
    <Routers/>
      
    </div>
  );
}

export default App;
