import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import CustomerSurvey from '../components/CustomerSurvey'
import SuccessPage from '../components/SuccessPage'

function Routers() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
            <Route path='/' element={<CustomerSurvey/>}/>
            <Route path='/SuccessPage' element={<SuccessPage/>} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default Routers
