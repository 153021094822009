import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Button, Container, Grid } from '@mui/material';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { useNavigate } from 'react-router-dom';

const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.action.disabled,

    },
}));

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon color="error" sx={{ fontSize: 65 }} />,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentDissatisfiedIcon color="error" sx={{ fontSize: 65 }} />,
        label: 'Dissatisfied',
    },
    3: {
        icon: <SentimentSatisfiedIcon color="warning" sx={{ fontSize: 65 }} />,
        label: 'Neutral',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon color="success" sx={{ fontSize: 65 }} />,
        label: 'Satisfied',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon color="success" sx={{ fontSize: 65 }} />,
        label: 'Very Satisfied',
    },
};

function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
};


function CustomerSurvey() {

    const [firstQ, setFirstQ] = React.useState(0);
    const [secondQ, setSecondQ] = React.useState(0);
    const [thirdQ, setThirdQ] = React.useState(0);
    const [fourthQ, setFourthQ] = React.useState(0);
    const [fifthQ, setFifthQ] = React.useState(0);
    const [ratingValue, setRatingValue] = React.useState(0);
    const [isSubmitted, setIsSubmitted] = React.useState(false);


    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitted(true);
        if (firstQ === 0 || secondQ === 0 || thirdQ === 0 ||
            fourthQ === 0 || fifthQ === 0 || ratingValue === 0) {
            // Do not submit the form, show error
            alert('Please select all the ratings');
        } else {
            // Proceed with form submission
            alert('Form submitted successfully!');
            navigate("/SuccessPage");
        }

    };

    const navigate = useNavigate();
    return (
        <Container>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1,
                        width: 1200,
                        height: 200,
                    },
                }}
            >

                <Paper className='border '>
                    <Grid className=''>

                        <Typography variant='h5' className='lg:pt-6 lg:pl-5  text-left  '>Please tell us how we did!</Typography>
                        <StyledRating

                            className='face-icon mt-[2.5rem] lg:ml-[4rem] float-left'
                            name="highlight-selected-only"
                            defaultValue={0}
                            value={ratingValue}
                            onChange={(event, newValue) => {
                                setRatingValue(newValue);
                            }}
                            IconContainerComponent={IconContainer}
                            getLabelText={(value) => customIcons[value].label}
                            highlightSelectedOnly
                        />
                        {/* {isSubmitted && ratingValue === 0 && (
                            <div style={{ color: 'red', marginTop: '0.5rem' }}>
                                This field is required
                            </div>
                        )} */}
                    </Grid>
                    <Grid>
                        <textarea cols="50" rows="4" type='text' placeholder='Please type your feedback here and let us know what to do better...'
                            className=' mt-2 lg:py-4 lg:px-4  lg:w-[40rem] lg:h-[7rem] lg:mt-3 outline-none' />
                    </Grid>
                </Paper>
                <Paper className='border'>
                    <Box
                        sx={{
                            '& > legend': { mt: 2 },
                        }}
                    >
                        <Typography variant='h5' className='lg:pt-6 lg:pl-5  text-left'
                            component="legend"> How satisfied were you with the punctuality of your technician?</Typography>
                        <Rating
                            className=' float-left lg:ml-[4rem] lg:mt-5 '
                            name="simple-controlled"
                            value={fifthQ}
                            onChange={(event, newValue) => {
                                setFifthQ(newValue);
                            }}
                            sx={{
                                // Change the color of filled stars
                                '& .MuiRating-iconFilled': {
                                    color: '#ff6d75', // Custom color for filled stars
                                },
                                // Change the color of empty stars
                                '& .MuiRating-iconEmpty': {
                                    color: '#ffb3b3', // Custom color for empty stars
                                },
                                // Change the color of hover stars
                                '& .MuiRating-iconHover': {
                                    color: '#ff3d47', // Custom color on hover
                                },
                                fontSize: 70
                            }}
                        />

                        {/* <div>
                            Selected Rating: {fifthQ} 
                        </div> */}

                    </Box>
                    <Grid>
                        <textarea cols="50" rows="4" type='text' placeholder='Please type your feedback here and let us know what to do better...'
                            className='lg:py-4 lg:px-4  lg:w-[40rem] lg:h-[7rem] lg:mt-3 outline-none' />
                    </Grid>
                </Paper>
                <Paper className='border'>
                    <Box
                        sx={{
                            '& > legend': { mt: 2 },
                        }}
                    >
                        <Typography variant='h5' className='lg:pt-6 lg:pl-5  text-left'
                            component="legend"> How would you rate the quality of the service provided?</Typography>
                        <Rating
                            className=' float-left lg:ml-[4rem] lg:mt-5'
                            name="simple-controlled"
                            value={secondQ}
                            onChange={(event, newValue) => {
                                setSecondQ(newValue);
                            }}
                            sx={{
                                // Change the color of filled stars
                                '& .MuiRating-iconFilled': {
                                    color: '#ff6d75', // Custom color for filled stars
                                },
                                // Change the color of empty stars
                                '& .MuiRating-iconEmpty': {
                                    color: '#ffb3b3', // Custom color for empty stars
                                },
                                // Change the color of hover stars
                                '& .MuiRating-iconHover': {
                                    color: '#ff3d47', // Custom color on hover
                                },
                                fontSize: 70
                            }}
                        />

                    </Box>
                    <Grid>
                        <textarea cols="50" rows="4" type='text' placeholder='Please type your feedback here and let us know what to do better...'
                            className='lg:py-4 lg:px-4  lg:w-[40rem] lg:h-[7rem] lg:mt-3 outline-none' />
                    </Grid>
                </Paper>
                <Paper className='border'>
                    <Box
                        sx={{
                            '& > legend': { mt: 2 },
                        }}
                    >
                        <Typography variant='h5' className='lg:pt-6 lg:pl-5  text-left'
                            component="legend"> How would you rate the professionalism and friendliness of your technician?</Typography>
                        <Rating
                            className=' float-left lg:ml-[4rem] lg:mt-5'
                            name="simple-controlled"
                            value={thirdQ}
                            onChange={(event, newValue) => {
                                setThirdQ(newValue);
                            }}
                            sx={{
                                // Change the color of filled stars
                                '& .MuiRating-iconFilled': {
                                    color: '#ff6d75', // Custom color for filled stars
                                },
                                // Change the color of empty stars
                                '& .MuiRating-iconEmpty': {
                                    color: '#ffb3b3', // Custom color for empty stars
                                },
                                // Change the color of hover stars
                                '& .MuiRating-iconHover': {
                                    color: '#ff3d47', // Custom color on hover
                                },
                                fontSize: 70
                            }}
                        />

                    </Box>
                    <Grid>
                        <textarea cols="50" rows="4" type='text' placeholder='Please type your feedback here and let us know what to do better...'
                            className='lg:py-4 lg:px-4  lg:w-[40rem] lg:h-[7rem] lg:mt-3 outline-none' />
                    </Grid>
                </Paper>
                <Paper className='border'>
                    <Box
                        sx={{
                            '& > legend': { mt: 2 },
                        }}
                    >
                        <Typography variant='h5' className='lg:pt-6 lg:pl-5  text-left'
                            component="legend"> How satisfied are you with the overall experience of scheduling and receiving the service?</Typography>
                        <Rating
                            className=' float-left lg:ml-[4rem] lg:mt-5'
                            name="simple-controlled"
                            value={fourthQ}
                            onChange={(event, newValue) => {
                                setFourthQ(newValue);
                            }}
                            sx={{
                                // Change the color of filled stars
                                '& .MuiRating-iconFilled': {
                                    color: '#ff6d75', // Custom color for filled stars
                                },
                                // Change the color of empty stars
                                '& .MuiRating-iconEmpty': {
                                    color: '#ffb3b3', // Custom color for empty stars
                                },
                                // Change the color of hover stars
                                '& .MuiRating-iconHover': {
                                    color: '#ff3d47', // Custom color on hover
                                },
                                fontSize: 70
                            }}
                        />

                    </Box>
                    <Grid>
                        <textarea cols="50" rows="4" type='text' placeholder='Please type your feedback here and let us know what to do better...'
                            className='lg:py-4 lg:px-4  lg:w-[40rem] lg:h-[7rem] lg:mt-3 outline-none' />
                    </Grid>
                </Paper>

                <Paper className='border'>
                    <Box
                        sx={{
                            '& > legend': { mt: 2 },
                        }}
                    >
                        <Typography variant='h5' className='lg:pt-6 lg:pl-5  text-left'
                            component="legend">  How likely are you to recommend our services to others?</Typography>
                        <Rating
                            className=' float-left lg:ml-[4rem] lg:mt-5'
                            name="simple-controlled"
                            value={firstQ}
                            onChange={(event, newValue) => {
                                setFirstQ(newValue);
                            }}
                            sx={{ fontSize: 70 }}
                        />

                    </Box>
                    <Grid>
                        <textarea cols="50" rows="4" type='text' placeholder='Please type your feedback here and let us know what to do better...'
                            className='lg:py-4 lg:px-4  lg:w-[40rem] lg:h-[7rem] lg:mt-3 outline-none' />
                    </Grid>
                </Paper>

            </Box>

            <Button sx={{ marginTop: 4, marginBottom: 2, width: 280, fontSize: 20 }}
                onClick={handleSubmit} variant="contained">SUBMIT</Button>
        </Container>
    )
}

export default CustomerSurvey
